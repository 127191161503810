/**
 * #.# Common SCSS
 *
 * Can include things like variables and mixins
 * that are used across the project.
*/

// Colors.
$black: rgb(41, 41, 41);
$white: #f4f4f4;
$gray: #dedede;
$green: #bada55;
$red: orangered;
